import React, { useRef } from 'react'
import './contact.css'
import {MdEmail} from 'react-icons/md'
import {FaFacebookMessenger} from 'react-icons/fa'

import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_rzwei0d', 'template_0364l47', form.current, '5EXmgCOeJww04k6fL')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()

  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className='container contact__container'>
        <div className='contact__options'>
          <article className='contact__option'>
            <MdEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>benbach2001@gmail.com</h5>
            <a href='mailto:benbach2001@gmail.com'>Send a message</a>
          </article>

          <article className='contact__option'>
            <FaFacebookMessenger className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h5>Định Bang</h5>
            <a href='https://www.facebook.com/dinh.bang.39/' target="_blank">Send a message</a>
          </article>

          <article className='contact__option'>
            <MdEmail className='contact__option-icon'/>
            <h4>Zalo</h4>
            <h5>+84 937 256 256</h5>
            <a href='https://chat.zalo.me/' target="_blank">Send a message</a>
          </article>
        </div>
         
        {/* END */}

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder='Your Email' required/>
          <textarea name='message' rows='7' placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>

      </div>
    </section>
  )
}

export default Contact