import React from 'react'
import './exp.css'
import {AiFillCheckCircle} from 'react-icons/ai'
const Experience = () => {
  return (
    <section id='experience'>
      <h5>Skills</h5>
      <h2>My Experience</h2>

      <div className='container experience__container'>
        <div className='experience__frontend'>
          <h3>Frontend Dev</h3>
          <div className='experience__content'>
            <article className='experience__details'>
              <AiFillCheckCircle className='experience__details-icon' />
              <div>
                <h4> HTML </h4>
                <small className='text-light'>2021</small>
              </div>
            </article>

            <article className='experience__details'>
              <AiFillCheckCircle className='experience__details-icon' />
              <div>
                <h4> CSS </h4>
                <small className='text-light'>2021</small>
              </div>
            </article>

            <article className='experience__details'>
              <AiFillCheckCircle className='experience__details-icon' />
              <div>
                <h4> JavaScript </h4>
                <small className='text-light'>2021</small>
              </div>
            </article>

            <article className='experience__details'>
              <AiFillCheckCircle className='experience__details-icon' />
              <div>
                <h4> ReactJS </h4>
                <small className='text-light'>2022</small>
              </div>
            </article>

            <article className='experience__details'>
              <AiFillCheckCircle className='experience__details-icon' />
              <div>
                <h4> Node JS</h4>
                <small className='text-light'>2022</small>
              </div>
            </article>

            <article className='experience__details'>
              <AiFillCheckCircle className='experience__details-icon' />
              <div>
                <h4> Figma/Canva </h4>
                <small className='text-light'>2021</small>
              </div>
            </article>
            
     
          </div>
        </div>
        {/* BackEnd */}
        <div className='experience__backend'>
          <h3>Backend Dev</h3>
            <div className='experience__content'>
              <article className='experience__details'>
                  <AiFillCheckCircle className='experience__details-icon' />
                  <div>
                    <h4> PHP </h4>
                    <small className='text-light'>2022</small>
                  </div>
              </article>

              <article className='experience__details'>
                  <AiFillCheckCircle className='experience__details-icon' />
                  <div>
                    <h4> Firebase </h4>
                    <small className='text-light'>2022</small>
                  </div>
              </article>
            </div>
          <h3>Version Control Systems</h3>
          <div className='experience__content'>
              <article className='experience__details'>
                  <AiFillCheckCircle className='experience__details-icon' />
                  <div>
                    <h4> GIT </h4>
                    <small className='text-light'>2022</small>
                  </div>
              </article>

          </div>
        </div>
      </div>
    </section>
  )
}


export default Experience