import React from 'react'
import './footer.css'
import {GrFacebook} from "react-icons/gr"
import {AiOutlineInstagram} from "react-icons/ai"
import {BsGithub} from "react-icons/bs"

const Footer = () => {
  return (
    <footer>
      <a href='#' className='footer__logo'>BANG BACH</a>

      <ul className='permalinks'>
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#experience'>Experience</a></li>
        {/* <li><a href='#'>Serivces</a></li> */}
        <li><a href='#portfolio'>Portfolio</a></li>
        {/* <li><a href='#testimonials'>Testimonials</a></li> */}
        <li><a href='#contact'>Contacts</a></li>
      </ul>

      <div className='footer__socials'>
        <a href='https://www.facebook.com/dinh.bang.39/'><GrFacebook/></a>
        <a href='https://www.instagram.com/'><AiOutlineInstagram/></a>
        <a href='https://github.com/BennyBach1002'><BsGithub/></a>
      </div>

      <div className='footer__copyright'>
        <small>&copy; BENB. All rights reserve</small>
      </div>

    </footer>
  )
}

export default Footer