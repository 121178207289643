import React from 'react'
import './about.css'
import ME from '../../assets/profile4.jpg'
import {FaAward} from 'react-icons/fa'
import {AiOutlineUser} from 'react-icons/ai'
import {AiOutlineFundProjectionScreen} from 'react-icons/ai'
const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className='container about__container'>
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={ME} alt="About image"/>
          </div>
        </div>

        <div className='about__content'>
          <div className='about__cards'>
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5> Experience </h5>
              <small>2 years of Experience</small>
            </article>

            <article className='about__card'>
              <AiOutlineUser className='about__icon'/>
              <h5> Skills </h5>
              <small>Finish multiple online Front-end Courses</small>
            </article>

            <article className='about__card'>
              <AiOutlineFundProjectionScreen className='about__icon'/>
              <h5> Projects </h5>
              <small>10 completed</small>
            </article>
          </div>

          {/* <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Id consequatur eaque vitae enim quidem nesciunt ullam totam corporis fugiat debitis labore possimus suscipit qui unde consequuntur eveniet eos, atque quos!
          </p> */}

          <a href='#contact' className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About