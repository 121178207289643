import React from 'react'
import {GrLinkedin} from 'react-icons/gr'
import {AiOutlineGithub} from 'react-icons/ai'
import {AiFillDribbbleCircle} from 'react-icons/ai'
const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href='https://www.linkedin.com/in/bach-bang-6799b4241/' target="_blank">
          <GrLinkedin/>
        </a>
        <a href='https://github.com/BennyBach1002' target="_blank">
          <AiOutlineGithub/>
        </a>
        {/* <a href='https://dribbble.com/bennybach2001' target="_blank">
          <AiFillDribbbleCircle/>
        </a> */}
    </div>
  )
}

export default HeaderSocials