import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.png'
import IMG2 from '../../assets/portfolio2.png'
import IMG3 from '../../assets/portfolio3.png'


const dataS = [
  {
    id: 1,
    image: IMG1,
    title: 'React Full-stack E-commerce Website',
    github: 'https://github.com/BennyBach1002/eShop',
    demo:'https://dribbble.com/shots/19749402-TERKE-Logistics-Shipping-Landing-Page'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Javascript Full-stack E-commerce Website',
    github: 'https://github.com/BennyBach1002/Full-Stack-Ecommerce-Website/tree/main/Full-stack-Web-Application-main',
    demo:'https://dribbble.com/shots/19749402-TERKE-Logistics-Shipping-Landing-Page'
  },
  {
    id: 3,
    image: IMG3,
    title: 'Weather-App',
    github: 'https://github.com/BennyBach1002/Weather-App',
    demo:'https://dribbble.com/shots/19749402-TERKE-Logistics-Shipping-Landing-Page'
  }
]


const portfolio = () => {
  return (
   <section id='portfolio'>
    <h5>My Recent Work</h5>
    <h2>Portfolio</h2>

    <div className='container portfolio__container'>
      {
        dataS.map(({id, image, title, github, demo}) => {
          return (
            <article key={id} className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={image} alt={title} />
          </div>

          <h3>{title}</h3>

          <div className='portfolio__item-cta'>
            <a href={github}
            className='btn' 
            target='_blank'>Github</a>

            {/* <a href={demo}
            className='btn btn-primary' 
            target='_blank'>Live Demo</a> */}
          </div>
      </article>
          )
        })
      }
    </div>
   </section>
  )
}

export default portfolio