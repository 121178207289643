import About from './components/about/About';
import Header from './components/header/Header';
import Nav from './components/nav/Nav';
import Exp from './components/exp/Exp';
import Services from './components/services/Services';
import Portfolio from './components/portfolio/Portfolio';
import Testimonials from './components/testimonials/Testimonials';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';


function App() {
  return (
    <>
      <Header/>
      <Nav />
      <About />
      <Exp />
      {/* <Services /> */}
      <Portfolio />
      {/* <Testimonials/> */}
      <Contact/>
      <Footer />
    </>
  );
}

export default App;
